import * as _ from 'lodash-es';
import Elmahio from 'elmah.io.javascript';
import Swal from 'sweetalert2';
import { useScriptTag } from '@vueuse/core';
import { closeModal } from 'jenesius-vue-modal';
import { App } from 'vue';
import { User } from 'oidc-client-ts';
import { ResourceConfig } from '@repo/core/resources';
import { AppConstants } from '@repo/core/constants';
import { useDxFireSheet } from '@/utils';
import { useAppStore, useAuthStore } from '@repo/vue/stores';
import { useEventStore, useRootStore } from '@/stores';

export class SetupPlugin {
  install(app: App) {
    const router = app.config.globalProperties.$router;
    const pinia = app.config.globalProperties.$pinia;
    const primevue = app.config.globalProperties.$primevue;

    // stores
    const authStore = useAuthStore(pinia);
    const appStore = useAppStore(pinia);
    const eventStore = useEventStore(pinia);
    const rootStore = useRootStore(pinia);

    // methods
    const clearRootStoreData = () => {
      const search = window.location.search;
      if ((_.includes(search, 'code=') || _.includes(search, 'error=')) && _.includes(search, 'state=')) {
        rootStore.clearCurrentUser();
        rootStore.clearCurrentClient();
        rootStore.clearCurrentPractice();
        rootStore.clearCurrentTicket();
        rootStore.clearCurrentYear();
        rootStore.clearCurrentTabs();

        console.log('rootStore data reset');
      }
    };

    const setEventStoreData = (user: User) => {
      const eventData = eventStore.eventData;
      eventData.userProfileId = user.profile?.sub;
      eventData.accessToken = user.access_token;
      eventData.expires = user.expires_at;

      eventStore.$patch({
        eventData
      });

      console.log('eventStore data saved');
    };

    // hooks
    router.isReady().then(async () => {
      authStore.$subscribe(
        (mutation, state) => {
          const user = state.user;
          if (user?.access_token) {
            if (user && user.access_token) {
              clearRootStoreData();
              setEventStoreData(user);
            }
          }
        },
        { immediate: true }
      );

      appStore.$subscribe(
        (mutation, state) => {
          console.log('scale', state.scale);
          console.log('isDark', state.isDark);
          console.log('locale', state.locale);
          console.log('inputStyle', state.inputStyle);

          if (state.inputStyle) {
            primevue.config.inputStyle = state.inputStyle as 'outlined' | 'filled' | undefined;
            document.body.className = 'p-input-' + state.inputStyle;
          }

          if (appStore.isIFrame) {
            document.documentElement.className = 'app-light';
            document.documentElement.style.fontSize = AppConstants.defaultScale + 'px';
          } else {
            document.documentElement.className = state.isDark ? 'app-dark' : 'app-light';
            document.documentElement.style.fontSize = state.scale + 'px';
          }

          const element = document.getElementById('theme-link') as HTMLLinkElement;

          if (element) {
            if (state.isDark && !appStore.isIFrame) {
              const color = ResourceConfig.darkThemeColor;
              element.href = `/themes/theme-dark/${color}/theme.css`;
            } else {
              const color = ResourceConfig.lightThemeColor;
              element.href = `/themes/theme-light/${color}/theme.css`;
            }
          }
        },
        { immediate: true }
      );

      if (!appStore.isIFrame) {
        router.beforeResolve((to: any, from: any, next: any) => {
          const isClientProcess = _.some(to.matched, (result) => result.name === 'process');
          if (isClientProcess && (!rootStore.practiceId || !rootStore.clientId)) {
            console.warn('You no longer have a valid client file selected. Try opening client file again.');
            next({ name: 'clients', replace: true });
          } else {
            next();
          }
        });
        // TODO: implement Google Tag Manager
        // router.afterEach((to, from, failure) => {
        //   if (!failure) sendToAnalytics(to.fullPath);
        // });
      }

      // configure elmah
      const logger = new Elmahio({ apiKey: ResourceConfig.elmahApiKey, logId: ResourceConfig.elmahLogId });
      window.Elmahio = Elmahio;

      app.config.errorHandler = async (err) => {
        console.error(err);

        if (ResourceConfig.enableElmahLogging === 'true') {
          logger.on('message', (msg) => {
            try {
              msg.application = 'dxcloud.ui.vite';
              msg.user = rootStore.userProfileId;
              msg.url = location.href;
            } catch (e) {
              console.log('Elmah: ', e);
            }
          });
          logger.error(err as string);
        }

        if (import.meta.env.MODE !== 'production') {
          await closeModal();

          Swal.fire({
            title: 'Error!',
            text: err as string,
            icon: 'error'
          }).then((r) => r);
        }
      };

      // load elmah
      if (ResourceConfig.enableElmahLogging === 'true') logger.information('DxCloud Vue project started!');

      // load firesheet
      const fs = useDxFireSheet();
      fs.loadFiresheetJs();

      // script tag
      const { load } = useScriptTag(
        `${ResourceConfig.serverBase}enums`,
        () => {
          console.log('enums loaded successfully');
        },
        { manual: true }
      );

      // load enums
      try {
        await load();
      } catch (e: any) {
        console.error('failed to load enums');
      } finally {
        app.mount('#app');
      }
    });
  }
}
