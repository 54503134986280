import * as _ from 'lodash-es';
import { ref } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { FinancialData, FinancialYear, FinancialYearOverview } from '@repo/core/models';
import { useFinancialDataService, useFinancialYearOverviewService, useFinancialYearService } from '@repo/core/services';
import { useRootStore } from '@/stores';

export const useFinancialStore = defineStore('financial', () => {
  // stores
  const rootStore = useRootStore();

  // services
  const financialYearService = useFinancialYearService();
  const financialDataService = useFinancialDataService();
  const financialYearOverviewService = useFinancialYearOverviewService();

  // state
  const periods = ref<FinancialYear[]>([]);
  const currentPeriod = ref<FinancialYear>({} as FinancialYear);
  const selectedPeriod = ref<FinancialYear>({} as FinancialYear);

  const financialYearsByFilter = ref<FinancialYear[]>([]);
  const financialYearOverview = ref<FinancialYearOverview>({});

  // methods
  const setDefaultPeriods = () => {
    const periods$ = _.filter(periods.value, (a: FinancialYear) => a.showOnRibbon) as FinancialYear[];

    if (!currentPeriod.value?.id) {
      currentPeriod.value = _.find(periods$, (p: FinancialYear) => p.current) as FinancialYear;
      selectedPeriod.value = { ...currentPeriod.value };
      rootStore.$patch({
        financialYearId: currentPeriod.value?.id
      });
    } else {
      const period$ = _.find(periods$, currentPeriod.value);
      if (!period$) {
        currentPeriod.value = _.last(periods$) as FinancialYear;
        selectedPeriod.value = { ...currentPeriod.value };
        rootStore.$patch({
          financialYearId: currentPeriod.value?.id
        });
      }
    }
  };

  // actions
  const loadFinancialOverviewData = async (financialYearId: string | undefined) => {
    const params = financialYearId ? '/' + financialYearId : '';
    const result = await fetchFinancialYearOverview(params);
    financialYearOverview.value = result;
    return result;
  };
  const fetchFinancialYear = async (financialYearId: string) => {
    const result = await financialYearService.getById(financialYearId);
    currentPeriod.value = result;
    selectedPeriod.value = result;
    rootStore.$patch({
      financialYearId: financialYearId
    });
    return result;
  };
  const saveFinancialData = (data: FinancialData[], options?: any) => {
    return financialDataService.post(data, options);
  };
  const fetchFinancialYears = async () => {
    const result = await financialYearService.get('?$orderby=end desc');
    _.forEach(result, (a) => {
      const period = _.find(periods.value, (t) => t.id === a.id);
      if (period) {
        _.assign(period, a);
      } else {
        periods.value.push(a);
      }
    });
    setDefaultPeriods();
    return result;
  };
  const fetchFinancialYearsByFilter = async (parameters?: string) => {
    const result = await financialYearService.get(parameters);
    _.forEach(result, (a) => {
      const period = _.find(financialYearsByFilter.value, (t) => t.id === a.id);
      if (period) {
        _.assign(financialYearsByFilter, a);
      } else {
        financialYearsByFilter.value.push(a);
      }
    });
    return result;
  };
  const fetchFinancialData = (parameters: string, options: any) => {
    return financialDataService.get(parameters, options);
  };
  const fetchFinancialDataByFilter = (parameters: string, options: any) => {
    return financialDataService.get(parameters, options);
  };
  const fetchFinancialYearOverview = async (parameters?: string) => {
    const result = await financialYearOverviewService.get(parameters);
    financialYearOverview.value = result;
    return result;
  };
  const addPriorFinancialYear = async () => {
    const result = await financialYearService.addPrior();
    currentPeriod.value = result[0];
    selectedPeriod.value = result[0];
    rootStore.$patch({
      financialYearId: result[0]?.id
    });
    return result;
  };
  const exportFinancialDataToExcel = (id: string, columns: any) => {
    return financialDataService.exportToExcel(id, columns);
  };
  const resetFinancialState = () => {
    rootStore.$patch({
      financialYearId: undefined
    });
    financialYearOverview.value = {};
    periods.value = [];
    currentPeriod.value = {} as FinancialYear;
    selectedPeriod.value = {} as FinancialYear;
  };
  const setSelectedPeriod = (data: FinancialYear) => {
    if (data?.id) {
      currentPeriod.value = { ...data };
      selectedPeriod.value = { ...data };
      rootStore.$patch({
        financialYearId: data.id
      });
    }
  };

  const state = {
    periods,
    currentPeriod,
    selectedPeriod,
    financialYearOverview
  };
  const actions = {
    loadFinancialOverviewData,
    fetchFinancialYear,
    saveFinancialData,
    fetchFinancialYears,
    fetchFinancialYearsByFilter,
    fetchFinancialData,
    fetchFinancialDataByFilter,
    fetchFinancialYearOverview,
    addPriorFinancialYear,
    exportFinancialDataToExcel,
    setSelectedPeriod,
    resetFinancialState
  };

  return { ...state, ...actions };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFinancialStore, import.meta.hot));
}
